<template>
    <el-container>
        <el-header class="Header">
            <el-row justify="space-between">
                <el-col :span="2">
                    <div class="Index" @click="ToIndex()">首页</div>
                </el-col>
                <el-col :span="2">
                    <div class="Active" @click="ToEvents">活动</div>
                </el-col>
                <el-col :span="2">
                    <div class="Square" @click="ToZone">广场</div>
                </el-col>
                <el-col :span="2">
                    <div class="QA" @click="ToMainQA">Q&A</div>
                </el-col>
                <el-col :span="4"><div class=""></div></el-col>
            </el-row>
        </el-header>
        <el-main>
            <div class="Item">
        <div class="TiltePage">活动详情页</div>
        <div class="InitActiveInfo">
            <div class="ActivityInfo">
                <div class="Title">活动标题:<span class="iteminfo">{{details.title}}</span></div>
                <div class="Fee">报名费用:<span class="iteminfo">{{details.fee}}元</span></div>
                <div class="Status">活动状态：<span  :style="color" id="colortext">{{statustext}}</span></div>
                <div class="Duration">持续时间:<span class="iteminfo">{{details.duration}}天</span></div>
                <div class="StartTime">起始时间:<span class="iteminfo">{{details.startDate}}</span></div>
                <div class="StartTime">结束时间:<span class="iteminfo">{{details.endDate}}</span></div>
                <div class="Describle">活动描述:</div>
                <div class="DescribleContent">{{details.description}}</div>
                <div class="Operation"><el-button size="mini" class="btn" @click="AddThisActivity()">加入活动</el-button></div> 
            </div>
            <div class="UserRewards">
                <div class="RewardsTitle">
                    活动分账记录
                </div>
                <div class="RewardsBody" >
                    <div class="Rewardsitem">
                        <div class="RewardsAvatar"></div>
                        <div class="RewardsDays">打卡天数</div>
                        <div class="RewardsName">昵称</div>
                        <div class="RewardsNumber">分账金额</div>
                    </div>
                    <div v-if="infoShow">
                        <div class="RewardsitemInfo" v-for="(item,index) in rewardsList" :key="index">
                            <div class="RewardsAvatarInfo"><img :src=item.icon></div>
                            <div class="RewardsDaysInfo">{{item.days}}</div>
                            <div class="RewardsNameInfo">{{item.nickname}}</div>
                            <div class="RewardsNumberInfo">{{item.amount}}￥</div>
                        </div>
                    </div>
                    <div v-else class="NoRes">
                        暂无记录
                    </div>
                </div>
            </div>
        </div>    
            <div class="ActivityUser">
                <div class="CurrentActivityUserList">
                    <div class="UserListTitle">
                        当前活动用户列表
                    </div>
                    <div class="UserList">
                        <div class="UserListContent" v-for="(item,index) in currentactiviesuserlist" :key="index">
                            <el-row class="ClockUser">
                            <el-col :span="4">
                                <div class="UserAvatar">
                                    <div class="Avatar">
                                        <img :src=item.icon >
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="UserNickName">
                                    {{item.nickname}}
                                </div>
                            </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="CurrentUserClockList">
                    <div class="itemListHeader">当前活动用户打卡记录</div>
                    <div class="itemListBody">
                        <div class="itemList"  v-for="(item,index) in currentactiviesuserclocklist" :key="index">
                            <div class="itemIcon"><img :src="item.icon"></div>
                            <div class="itemInfo">{{item.goalName}}</div>
                            <div class="itemNickname">{{item.nickname}}</div>
                            <div class="ClockInTime">{{item.clockInTime}}</div>
                        </div>
                    </div>
                    <div class="Pagina">
                        <el-button size="mini" class="item-pre" @click="PreItem" :disabled="pageable_pre">上一页</el-button>
                        <el-button size="mini" class="item-next" @click="NextItem" :disabled="pageable_next">下一页</el-button>
                    </div>
                </div>        
            </div>
    </div>
        </el-main>
    </el-container>
    
</template>

<script>
import {GetActiveDetails,GetCurrentActivityUserList,AddActivity,GetCurrentActivityUserClockInList,GetUserRewards} from "@/api/active.js"
// import '../assets/rem.js'
export default {
    name:'ActiveDetails',
    props:['id'],
    data(){
        return{
            activityid:'',
            details:'',
            currentactiviesuserlist:'',
            currentactiviesuserclocklist:'',

            pageNo:1,
            PageSize:10,

            pageable_pre:true,
            pageable_next:false,

            count:1,

            statustext:'',
            color:'',

            rewardsList:[],
            infoShow:'',
        }
    },
    created(){
        this.infoShow = ''
        this.activityid = this.id
        this.GetDetailsInfo()
        this.GetCurrentActivityUserListInfo()
        this.GetActiveClockInUserList()
        // this.GetTotalOfClockIn()
        this._GetUserRewardsInfo()
    },
    mounted(){
        
    },
    methods:{
        async _GetUserRewardsInfo(){
            let res = await GetUserRewards(this.activityid)
            this.rewardsList = res.data.list
            if(this.rewardsList.length != 0){
                this.infoShow = true
            }else{
                this.infoShow = false
            }
        },
        PreItem(){
            this.count -= 1
            if(this.count == 1){
                this.pageable_pre = true
                this.pageable_next = false
                this.pageNo = this.count
                GetCurrentActivityUserClockInList(this.activityid,this.pageNo,this.PageSize).then(res=>{
                    this.currentactiviesuserclocklist = res.data.list
                })
            }else{
                this.pageable_next = false
                this.pageNo = this.count
                GetCurrentActivityUserClockInList(this.activityid,this.pageNo,this.PageSize).then(res=>{
                    this.currentactiviesuserclocklist = res.data.list
                })
            }
        },
        NextItem(){
            this.count += 1
            this.pageable_pre = false
            this.pageNo = this.count
            GetCurrentActivityUserClockInList(this.activityid,this.pageNo,this.PageSize).then(res=>{
                this.currentactiviesuserclocklist = res.data.list
                if(this.currentactiviesuserclocklist.length == this.PageSize){
                    this.pageable_next = false
                }else{
                    this.pageable_next = true
                }
            })
        },
        ToIndex(){
            this.$router.push({
                path:'/index'
            })
        },
        ToEvents(){
            this.$router.push({path:'/events'})
        },
        ToZone(){
            this.$router.push({path:'/zone'})
        },
        ToMainQA(){
            this.$router.push({path:'/mainqa'})
        },
        GetDetailsInfo(){
            GetActiveDetails(this.activityid).then(res=>{
                this.details = res.data
                if(this.details.status == -1){
                    this.statustext = '活动已结束'
                    // let colorDom = document.getElementById('colortext')
                    // colorDom.style.color = 'red'
                    this.color = {'color':'red'}
                }else if(this.details.status == 1){
                    this.statustext = '活动进行中...'
                    // let colorDom = document.getElementById('colortext')
                    // colorDom.style.color = 'green'
                    this.color = {'color':'#4bbe4b'}
                }else{
                     this.statustext = '活动尚未开始'
                }
            })
        },
        GetCurrentActivityUserListInfo(){
            GetCurrentActivityUserList(this.activityid).then(res=>{
                this.currentactiviesuserlist = res.data.list
            })
        },
        AddThisActivity(){
            AddActivity(this.activityid).then(res=>{
                if(res.code == 0){
                    this.$message({message: '恭喜你，成功加入该活动',type: 'success'});
                }
            })
        },
        GetActiveClockInUserList(){
            GetCurrentActivityUserClockInList(this.activityid,this.pageNo,this.PageSize).then(res=>{
                this.currentactiviesuserclocklist = res.data.list
                GetCurrentActivityUserClockInList(this.activityid,this.pageNo+1,this.PageSize).then(res=>{
                        let nextmark = res.data.list
                        if(nextmark.length == 0){
                            this.pageable_next = true
                        }else{
                            this.pageable_next = false
                        }
                })
            })
        }
    }
}
</script>

<style scoped>
.Header{
    line-height: 60px;
    width: 100%;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 20;
}
.Index , .Square , .QA ,.Active{
  height: 100%;
  font-size: 16px;
  cursor: pointer;
}
.Index:hover ,.Active:hover, .Square:hover , .QA:hover {
  background: #dddddd;
}
.Item{
    width: 100%;
    height: 100%;
}
.TiltePage{
    padding: 30px 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    font-weight: 500;
    color: white;
}
.InitActiveInfo{
    width: 80%;
    margin: 0 auto;
    display: flex;
}
.ActivityInfo{
    position: relative;
    /* width: 80%; */
    flex: 0 0 70%;
    margin: 0 auto;
    height: 300px;
    border-radius: 4px 0px 0px 4px;
    background-color: rgb(199, 205, 205);
}
.UserRewards{
    flex: 0 0 30%;
    /* padding: 5px; */
    background-color: #383838;
    border-radius: 0px 4px 4px 0px

}
.RewardsTitle{
    /* width: 100%; */
    color: white;
    padding: 5px;
    height: 30px;
    /* border: 1px solid; */
    line-height: 30px;
    font-size: 18px;
}
.RewardsBody{
    /* width: 100%; */
    padding:0px 5px;
    height: 260px;
    background-color: white;
    overflow: auto;
    border-radius: 0px 0px 4px 0px
}
.RewardsBody::-webkit-scrollbar{
    display: none;
}
.Rewardsitem{
    position: sticky;
    top: 0;
    color: white;
    width: 100%;
    height: 30px;
    padding: 5px 0;
    background-color: #6c6c6c;
    display: flex;
    align-items: center;
}
.RewardsAvatar{
    width: 30px;
    height: 100%;
}
.RewardsDays{
    flex: 0 0 30%;
    font-size: 14px;
}
.RewardsName{
    flex: 0 0 30%;
    font-size: 14px;
}
.RewardsNumber{
    flex: 1;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.RewardsitemInfo{
    width: 100%;
    height: 30px;
    padding: 5px 0;
    margin-top: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    color: white;
    background-color: rgb(144, 148, 148);
}
.RewardsAvatarInfo{
    position: relative;
   width: 30px;
   height: 30px; 
}
.RewardsAvatarInfo img{
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
}
.RewardsDaysInfo{
    flex: 0 0 30%;
    font-size: 14px;
}
.RewardsNameInfo{
    flex: 0 0 30%;
    font-size: 14px;
}
.RewardsNumberInfo{
    flex: 1;
    font-size: 14px;
}
.NoRes{
    font-size: 16px;
}


.Title,.Fee,.Duration,.Status,.StartTime,.Describle{
    padding: 0 0 0 10px;
    text-align: left;
    line-height: 30px;
    font-size: 15px;
    font-weight: 600;
}
.DescribleContent{
    padding: 10px  0 0 15px;
    font-size: 14px;
    font-weight: lighter;
    text-align: left;
}
.iteminfo{
    font-size: 14px;
    font-weight: lighter;
}
.Operation{
    position: absolute;
    display: flex;
    height: 30px;
    bottom: 0;
    right: 0;
}
.ActivityUser{
    padding: 20px 0;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    display: flex;
}
.CurrentActivityUserList{
    flex: 1;
    height: 300px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
}
.UserListTitle{
    line-height: 30px;
    height: 10%;
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
}
.UserList{
    position: relative;
    width: 100%;
    flex: 1;
    /* height: 85%; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    overflow: auto;
    box-sizing: border-box;
    padding: 0 0 0 0.05rem;
    background-color: #c7cdcd;
}
.UserList::-webkit-scrollbar{
    display: none;
}
.UserListContent{
    width: 100%;
}
.ClockUser{
    margin-top: 5px;
    border-radius: 4px;
    background-color: #f0f8ff;
}
.UserAvatar{
    /* position: relative;
    display: flex; */
}
.Avatar{
    position: relative;
    width: 40px;
    height: 40px;
    z-index: 10;
}
.Avatar img{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}
.UserNickName{
    line-height: 40px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}
.CurrentUserClockList{
    flex: 1;
    height: 300px;
    background-color: rgb(199, 205, 205);
    overflow: hidden;
}
.CurrentUserClockList::-webkit-scrollbar{
    display: none;
}
.itemListBody{
    width: 100%;
    height: 225px;
    overflow: auto;
}
.itemListBody::-webkit-scrollbar{
    display: none;
}
.itemListHeader{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    padding: 5px 5px;
    text-align: center;
    background-color: aliceblue;
    position: sticky;
    top: 0;
}
.itemList{
    display: flex;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 5px 5px;
    text-align: left;
    border-radius: 4px;
    background-color: aliceblue;
    margin: 5px;
}
.Pagina{
    position: relative;
    width: 100%;
    height: 35px;
    background-color: rgb(255, 255, 255);
    /* padding: 5px 0px; */
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
}
.item-pre{
  position: absolute;
  left: 10px;
}
.item-next{
  position: absolute;
  right: 10px;
}
.itemIcon{
    flex: 0 0 40px;
}
.itemIcon img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.itemInfo{
    flex: 1;
    overflow:hidden;
    text-overflow:ellipsis;
    padding-left: 10px;
}
.itemNickname{
    flex: 0 0 20%;
    font-size: 14px;
    line-height: 40px;
    overflow:hidden;
    text-overflow:ellipsis;
}
.ClockInTime{
  flex: 0 0 30%;
  font-size: 14px;
  line-height: 40px;
  overflow:hidden;
  text-overflow:ellipsis;
}


</style>